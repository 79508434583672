
<script>
import AvatarCropper from "vue-avatar-cropper";
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    AvatarCropper,
  },
  data() {
    return {
      showCropper: false,
      processing_image: false,
      uploadURL: this.$axios.defaults.baseURL + "/my-profile-image",
    };
  },
   validations: {
                   restaurant:{
                     user:{
                       name:{
                           required,
                       },
                       email:{
                           required,
                       },
                       password:{
                           required,
                       },
                     }

                   } ,
   },
  computed: {
    restaurant: {
      get() {
        return this.$store.state.restaurant.restaurant;
      },
      set(value) {
        this.$store.commit("updateRestaurant", value);
      },
    },
  },
  mounted() {
    
  },

  methods: {
    handleUploading(form, xhr) {
      this.processing_image = true;
    },
    handleUploaded(response) {
      this.processing_image = false;

      if (response) {
        this.restaurant.user.avatar = response;
        this.triggerSwal("Image Uploaded Successfully!", "success");
      }
    },
    handleCompleted(response, form, xhr) {
      this.processing_image = false;
    },
    handlerError(message, type, xhr) {
      this.processing_image = false;
      this.triggerSwal(
        "There is a problem in updating your profile image.",
        "error"
      );
    },
  },
};
</script>

<template>
  <div>
    <form class="form-horizontal" role="form" id="location_form">
      <div class="row">
        <div class="col-md-4 disp-flex align__items__center mt-2 mb-2">
          <div>
            <div class="card vue-avatar-cropper-demo text-center">
              <div class="card-body">
                 <img
                    :src="restaurant.user.avatar_path"
                    class="card-img avatar"
                  />
              </div>

              <avatar-cropper
                @uploading="handleUploading"
                @uploaded="handleUploaded"
                :toBlob="true"
                @completed="handleCompleted"
                :labels="{ submit: 'Upload', cancel: 'Cancel' }"
                @error="handlerError"
                trigger="#pick-avatar"
                :upload-url="uploadURL"
              />
              <!-- :upload-headers="uploadHeaders" -->

              <button class="btn btn-primary btn-sm" id="pick-avatar">
                <span> Select a new image </span>
              </button>
            </div>
          </div>
          <div class="ml-2" v-if="processing_image">
            <span class="spinner-grow spinner-grow-sm"></span>
            <span>Profile Image is Uploading ...</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            class="mb-3"
            id="example text"
            label="Name"
            label-for="name"
          >
            <b-form-input for="name" v-model="restaurant.user.name"></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="example-email" label="Email" label-for="email">
            <b-form-input
              id="email"
              v-model="restaurant.user.email"
              type="email"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group id="example-contact" label="Phone" label-for="contact">
            <b-form-input
              id="contact"
              v-model="restaurant.user.phone"
              type="number"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            id="input-group-3"
            label="Password"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="restaurant.user.password"
              type="password"
              required
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
    </form>
  </div>

  <!-- end .border-->
</template>

<style scoped>
.my-form .vue-avatar-cropper-demo .card-body{
 z-index: 2;
}
.my-form .vue-avatar-cropper-demo {
    height: 250px;
    width: 250px;
    position: relative;
}
.my-form #pick-avatar{
  position: absolute;
  height:100%;
  width: 100%;
  background: transparent;
  color: #222;
  z-index: 3;
    border: 1px solid #f251b0 !important;
}
.my-form #pick-avatar span{
 
  z-index: 0;

}

.my-form button#pick-avatar:before {
    position: absolute;
    content: '\ea3d';
    font-family: unicons-line;
    font-size: 18px;
    top: 11px;
    z-index: 999;
    width: 20px;
    height: 20px;
    right: 10px;
} 
</style>

<script>
    import Layout from "@/views/pages/super_admin/layout/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import {mapState} from "vuex";

    import createRestaurantOwnerForm from "@/views/pages/super_admin/restaurant_owner/components/createRestaurantOwnerForm";
    import createRestaurantForm from "@/views/pages/super_admin/restaurant_owner/components/createRestaurantForm";
    
    export default {
        page: {
            title: "Team",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,createRestaurantOwnerForm,createRestaurantForm
        },

        mounted(){
        this.fetchRestaurant();
        },

        methods: {
           fetchRestaurant() {
                this.$axios.get("/restaurant/"+this.$route.params.restaurant_id).then((response) => {
                let data = response.data.payload;
                this.$store.state.restaurant.restaurant=data
                }).catch(error => {
                this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },

            updateRestaurant(){
                let self = this;
                if(this.$refs.createRestaurantOwnerForm.$v.restaurant.user.password.$invalid === true){
                    self.triggerSwal("Please Add Password", "error");
                    return;
                }
                if (self.processing) {
                return;
                }
                
                self.processing = true;
                
                self.$axios.post('/restaurant/update-restaurant/'+this.$route.params.restaurant_id, {
                restaurant:self.restaurant
                    
                })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                    // self.resetState();
                    
                  self.$router.push("/restaurant-listing");
                  
                })
                .catch(error => {
                    self.handleErrorResponse(error.response, "error");
                    
                })
                .finally(function () {
                    self.processing = false;
                });
            },
      
        },

         computed: {
             ...mapState({
            restaurant: state => state.restaurant.restaurant
                }),
            },

        data() {
            return {
                title: "My Profile",
                items: [
                    {
                        text: "My Profile",
                    },
                    {
                        text: "My Profile",
                        active: true,
                    },
                ],
                processing:false,
                currentTabComponent: 'createRestaurantOwnerForm',
                tabs: [
                        {
                            id:1,
                            desc: 'Edit Restaurant Owner',
                            component_name: 'createRestaurantOwnerForm',
                            icon: 'fa-file'
                        },
                        {
                            id:2,
                            desc: 'Edit Restaurant',
                            component_name: 'createRestaurantForm',
                            icon: 'fa-file'
                        },
                ],
            };
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" />
        <div class="row">
        <div class="col-12 col-md">
            <ul class="nav nav-pills tabs-pills" role="tablist">
                <li class="nav-item" v-for="(tab,index) in tabs" :key="index">
                    <a class="nav-link d-flex align-items-center"
                       :class="{active: currentTabComponent === tab.component_name}"
                       :id="tab.id"
                       @click="currentTabComponent = tab.component_name"
                       data-toggle="tab" href="#loan-file" aria-controls="loan-file" role="tab"
                       aria-selected="true">
                        <i class="me-2 fas" :class="tab.icon"></i><span class="d-none d-sm-block">{{ tab.desc }}</span>
                    </a>
                </li>
            </ul>
        </div>
        </div>
        <div class="card">
            <div class="card-body under-tabs">
                <div class="row">
                    <div class="col-12">
                        <div class="tab-content">
                    <component :is="currentTabComponent" ref="createRestaurantOwnerForm"></component>
                        </div>
                    </div>
                </div>
            </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12">
                    <div class="float-right">
                        <submit-button @clicked="updateRestaurant" :processing="processing"
                                       text="Update">
                        </submit-button>
                    </div>
                </div>
            </div>

        </div>
        </div>
    </Layout>
</template>

<template>
  
                            <div>
                                <form class="form-horizontal" role="form" id="restaurant_form">
                                  <div class="row">
        <div class="col-md-4 disp-flex align__items__center mt-2 mb-2">
          <div>
            <div class="card vue-avatar-cropper-demo text-center">
              <div class="card-body">
                 <img
                    :src="restaurant.logo==null ? null : assets_url + restaurant.logo"
                    class="card-img avatar"
                  />
              </div>

              <avatar-cropper
                @uploading="handleUploading"
                @uploaded="handleUploaded"
                :toBlob="true"
                @completed="handleCompleted"
                :labels="{ submit: 'Upload', cancel: 'Cancel' }"
                @error="handlerError"
                trigger="#pick-logo"
                :upload-url="uploadURL"
              />
              <!-- :upload-headers="uploadHeaders" -->

              <button class="btn btn-primary btn-sm" id="pick-logo">
                <span> Select a Logo </span>
              </button>
            </div>
          </div>
          <div class="ml-2" v-if="processing_image">
            <span class="spinner-grow spinner-grow-sm"></span>
            <span>Image is Uploading ...</span>
          </div>
        </div>
      </div>
                                    <div class="row">
                                       
                                        <div class="col-md-6">
                                            <b-form-group class="mb-3" id="restaurant_name" label="Restaurant Name" label-for="restaurant_name">
                                                <b-form-input for="restaurant_name" v-model="restaurant.name" ></b-form-input>
                                            </b-form-group>
                                        </div>
                                       
                                        <div class="col-md-6">
                                            <b-form-group id="example-country" label="Country" label-for="country">
                                                <b-form-input id="country" v-model="restaurant.country" type="text"></b-form-input>
                                            </b-form-group>
                                        </div>

        </div>
      </form>
    </div>

    <!-- end .border-->
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";
import { required } from 'vuelidate/lib/validators';


export default {
     components: {
       AvatarCropper
        },
        data() {
            return {
                countries: [{ text: "Select Country", value: null }, "UK", "USA"],
               showCropper: false,
              processing_image: false,
              uploadURL: this.$axios.defaults.baseURL + "/restaurant/restaurant-logo",
    }
  },
   validations: {
                   restaurant:{
                     name:{
                         required,
                     },
                     country:{
                         required,
                     },
                    
                   } ,
   },
  computed: {
    restaurant: {
      get() {
        return this.$store.state.restaurant.restaurant;
      },
      set(value) {
        this.$store.commit("updateRestaurant", value);
      },
    },
  },
  

  methods: {
     handleUploading(form, xhr) {
      this.processing_image = true;
    },
    handleUploaded(response) {
      this.processing_image = false;

      if (response) {
        this.restaurant.logo = response;
        this.triggerSwal("Image Uploaded Successfully!", "success");
      }
    },
    handleCompleted(response, form, xhr) {
      this.processing_image = false;
    },
    handlerError(message, type, xhr) {
      this.processing_image = false;
      this.triggerSwal(
        "There is a problem in updating your profile image.",
        "error"
      );
    },
  },
};
</script>
<style scoped>
.my-form .vue-avatar-cropper-demo .card-body{
 z-index: 2;
}
.my-form .vue-avatar-cropper-demo {
    height: 250px;
    width: 250px;
    position: relative;
}
.my-form #pick-logo{
  position: absolute;
  height:100%;
  width: 100%;
  background: transparent;
  color: #222;
  z-index: 3;
    border: 1px solid #f251b0 !important;
}
.my-form #pick-logo span{
 
  z-index: 0;

}

.my-form button#pick-logo:before {
    position: absolute;
    content: '\ea3d';
    font-family: unicons-line;
    font-size: 18px;
    top: 11px;
    z-index: 999;
    width: 20px;
    height: 20px;
    right: 10px;
} 
</style>